import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Section from '$ui/section';
import Heading from '$ui/heading';

const GalleryGrid = ({ children }) => (
  <div className='grid grid-cols-[repeat(auto-fit,minmax(14rem,1fr))] gap-x-5 gap-y-10 px-40 mt-10 tab-port:px-8'>
    {children}
  </div>
);

const ImagesPage = () => {
  const {
    allCloudinaryMedia: { edges: images }
  } = useStaticQuery(graphql`
    query MyQuery {
      allCloudinaryMedia {
        edges {
          node {
            secure_url
            folder
          }
        }
      }
    }
  `);

  const meet2011Images = useMemo(
    () =>
      images.filter(
        image => image.node.folder === 'frontend/gallery/national-meet/2011'
      ),
    [images]
  );

  const meet2012Images = useMemo(
    () =>
      images.filter(
        image => image.node.folder === 'frontend/gallery/national-meet/2012'
      ),
    [images]
  );

  const meet2013Images = useMemo(
    () =>
      images.filter(
        image => image.node.folder === 'frontend/gallery/national-meet/2013'
      ),
    [images]
  );

  const meet2014Images = useMemo(
    () =>
      images.filter(
        image => image.node.folder === 'frontend/gallery/national-meet/2014'
      ),
    [images]
  );

  const meet2015Images = useMemo(
    () =>
      images.filter(
        image => image.node.folder === 'frontend/gallery/national-meet/2015'
      ),
    [images]
  );

  const meet2016Images = useMemo(
    () =>
      images.filter(
        image => image.node.folder === 'frontend/gallery/national-meet/2016'
      ),
    [images]
  );

  return (
    <>
      <Section id='images' className='pt-32'>
        <Heading>National meet 2011</Heading>
        <GalleryGrid>
          {meet2011Images?.map((image, i) => (
            <img key={i} src={image.node.secure_url} alt='images' />
          ))}
        </GalleryGrid>
      </Section>

      <Section className='pt-32'>
        <Heading>National meet 2012</Heading>
        <GalleryGrid>
          {meet2012Images?.map((image, i) => (
            <img key={i} src={image.node.secure_url} alt='images' />
          ))}
        </GalleryGrid>
      </Section>

      <Section className='pt-32'>
        <Heading>National meet 2013</Heading>
        <GalleryGrid>
          {meet2013Images?.map((image, i) => (
            <img key={i} src={image.node.secure_url} alt='images' />
          ))}
        </GalleryGrid>
      </Section>

      <Section className='pt-32'>
        <Heading>National meet 2014</Heading>
        <GalleryGrid>
          {meet2014Images?.map((image, i) => (
            <img key={i} src={image.node.secure_url} alt='images' />
          ))}
        </GalleryGrid>
      </Section>

      <Section className='pt-32'>
        <Heading>National meet 2015</Heading>
        <GalleryGrid>
          {meet2015Images?.map((image, i) => (
            <img key={i} src={image.node.secure_url} alt='images' />
          ))}
        </GalleryGrid>
      </Section>

      <Section className='pt-32'>
        <Heading>National meet 2016</Heading>
        <GalleryGrid>
          {meet2016Images?.map((image, i) => (
            <img key={i} src={image.node.secure_url} alt='images' />
          ))}
        </GalleryGrid>
      </Section>
    </>
  );
};

export default ImagesPage;
